<template>
    <el-dialog
        width="500px"
        top="40px"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :before-close="() => !$wait.is('*') && $emit('close')"
        @closed="resetForm"
    >
        <span slot="title">
            <div class="absolute w-full top-0 inset-x-0 border-b rounded-t-lg p-4 bg-gray-100">
                <p>
                    {{ $t('services.add_category') }}
                </p>
            </div>
        </span>

        <div class="mt-10 mb-20 px-10">
            <el-form
                ref="form"
                class="category-add-form px-10"
                :model="formData"
                label-position="top"
                :rules="rules"
                @submit.native.prevent
                @keyup.native.enter="addCategory"
            >
                <el-form-item :label="$t('services.name')" prop="name">
                    <el-input v-model="formData.name" />
                </el-form-item>
            </el-form>
        </div>

        <div class="flex justify-end rounded-b-lg border-t w-full p-3 bg-gray-100 absolute bottom-0 inset-x-0">
            <span slot="footer">
                <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
                <el-button type="primary" :loading="$wait.is('adding')" @click="addCategory">{{ $t('common.add') }}</el-button>
            </span>
        </div>
    </el-dialog>
</template>
<script>
import Api from '../categories.api';

export default {
    props: {
        visible: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            formData: {
                name: '',
            },
            rules: {
                name: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    methods: {
        async addCategory() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));

            try {
                this.$wait.start('adding');
                await Api.create(this.formData);
                this.$notify.success({ title: this.$t('common.success') });
                this.$emit('refresh');
            } finally {
                this.$wait.end('adding');
                this.closeModal();
            }
        },

        closeModal() {
            this.$emit('close');
        },

        resetForm() {
            this.$refs.form.resetFields();
            this.formData = {
                name: '',
            };
        },
    },
};
</script>
